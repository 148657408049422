export const Bio = {
  name: "Sourabh Upadhyay",
  roles: [
    "an Aspiring Developer",
    "a Programmer",
    "a Tech Enthusiast",
  ],
  description: "I am an experienced Dot Net developer with a passion for the MERN stack. I have been developing web applications with Dot Net for past 1+ year.However, I am also excited in MERN stack technology which I am Learning it nowdays. I am confident that my skills as a Dot Net developer will translate well to the MERN stack, and I am excited to contribute to the development of innovative web applications with this technology. With a passion for learning I am dedicated to delivering high-quality results.",

  github: "https://github.com/usourabh",
  resume:
    "https://drive.google.com/file/d/1nCy49ZV6g4gCmLEAYElhbioQ3_ScT_Sh/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/sourabh-upadhyay99/",
  twitter: "https://twitter.com/msourabhu"
};

export const skills = [
  {
    // ==================================================== - FRONTEND - ==================================================== 
    // ==================================================== - FRONTEND - ==================================================== 
    // ==================================================== - FRONTEND - ==================================================== 

    title: "Frontend",
    skills: [
      {
        name: "Jquery",
        image: "https://i.imgur.com/DowMJSq.png",
      },
      {
        name: "Ajax",
        image: "https://i.imgur.com/MUAZHoe.jpg",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
      {
        name: "Learning React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Learning Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
    ],
  },
  {
        // ==================================================== - BACKEND - ==================================================== 
        // ==================================================== - BACKEND - ==================================================== 
        // ==================================================== - BACKEND - ==================================================== 

    title: "Backend",
    skills: [
      {
        name: "Dot Net Core",
        image: "https://i.imgur.com/v0nygDY.png",
      },
      {
        name: "Entity Framework",
        image: "https://i.imgur.com/2Jw62nY.png",
      },
      {
        name: "SQL-SSMS",
        image:
          "https://www.clipartmax.com/png/small/110-1107214_sql-logo-microsoft-sql-server-logo.png",
      },
      {
        name: "C#",
        image:
          "https://i.imgur.com/yrd30ks.png",
      },
      {
        name: "Learning Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "Learning Express Js",
        image:
          "https://i.imgur.com/T0OFJ4D.png",
      },
      {
        name: "Learning MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
    ],
  },

  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://camo.githubusercontent.com/93b32389bf746009ca2370de7fe06c3b5146f4c99d99df65994f9ced0ba41685/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f676574706f73746d616e2f676574706f73746d616e2d69636f6e2e737667",
      },
      {
        name: "Learning Figma",
        image:
          "https://camo.githubusercontent.com/ed93c2b000a76ceaad1503e7eb9356591b885227e82a36a005b9d3498b303ba5/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6669676d612f6669676d612d69636f6e2e737667",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
        // ==================================================== - BACKEND - ==================================================== 
        img: "https://i.imgur.com/JDQWNTV.png",
    role: "Software Engineer",
    company: "Ajeevi Technologies Pvt. Ltd.",
    date: "May 2022 - Present",
    desc: "Worked on Multiple Enterprise level Software Like DTC-Corporation, Cadbury, Blue-Planet with Api Consumption.",
    skills: [
      "HTML",
      "CSS",
      "Bootstrap",
      "JavaScript",
      "jquery",
      "ajax",
      "API",
      "Dot Net Core",
      "SQL-SSMS",
      "C#",
      "winforms",
    ]
  },
  {
    id: 1,
    img: "https://i.imgur.com/JDQWNTV.png",
    role: "Software Engineer Intern",
    company: "Ajeevi Technologies Pvt. Ltd.",
    date: "April 2021 - April 2021",
    desc: "Worked on the frontend of the web application of Kumbh Haridwar fixing multiple bugs and created new webpages.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
    ]
  }
];

export const education = [
  {
    id: 0,
    img: "https://i.imgur.com/XI5PWzV.png",
    school: "HIMT Group of Institutions, Greater Noida (UP)",
    date: "April 2019 - August 2021",
    grade: "8.1 CGPA",
    desc: "I completed my MCA a Computer Science equivalent degree at HIMT, Greater Noida. I have completed 4 semesters and holds a CGPA of 8.71. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others.",
    degree: "[AKTU] Masters of Computer Application (MCA)",
  },
  {
    id: 1,
    img: "https://i.imgur.com/XI5PWzV.png",
    school: "HIMT Group of Institutions, Greater Noida (UP)",
    date: "April 2016 - Apr 2019",
    grade: "65%",
    desc: "I completed my BCA which is a bachelor degree in computer application in which I took variety of computer application subjects like computer networking, computer architecture, C, C++ etc",
    degree: "[CCS] Bachelor of Computer Applications (BCA)",
  },
  {
    id: 2,
    img: "https://i.imgur.com/JG24q2a.jpg",
    school: "SLS DAV Public School, Mausam Vihar, Delhi",
    date: "March 2015 - April 2016",
    grade: "65%",
    desc: "I completed my class-12 education at DAV PUBLIC School, Mausam Vihar Delhi, India. where I took Psychology , Information Practices, English as my primary subjects.",
    degree: "CBSE, Class XII",
  },
  {
    id: 3,
    img: "https://i.imgur.com/JG24q2a.jpg",
    school: "SLS DAV Public School, Mausam Vihar, Delhi",
    date: "March 2013 - March 2014",
    grade: "6.6 CGPA",
    desc: "I completed my class 10 education at DAV PUBLIC School, Mausam Vihar Delhi, India where I studied Science.",
    degree: "CBSE, Class X",
  },
];

export const projects = [
  
  {
    id: 0,
    title: "Github Issues Feature Clone",
    date: "April 2023 - May 2023",
    description:
      "Developed and tried to clone github issues web application that allows users to create new issues, work on it according to if its closed and open with React JS which saves the data in local storage that provides users with a seamless experience across all devices.",
    image:
      "https://i.imgur.com/U2Vxwc1.png",
    tags: ["React Js", "Local Storage", "Node Js", "Redux", "Tailwind-css"],
    category: "web app",
    github: "https://github.com/usourabh/git-issues-clone",
    webapp: "https://git-issues-clone-assignment.netlify.app/",
    member: [
      {
        name: "Sourabh",
        img: "https://i.imgur.com/SPD9Den.jpg",
        linkedin: "https://www.linkedin.com/in/sourabh-upadhyay99/",
        github: "https://github.com/usourabh/",
      },
    ],
  },
  {
    id: 1,
    title: "Notsy",
    date: "February 2022",
    description:
      "Developed and created a notes application using HTML, CSS and Javascript which stores your notes in local storage of the browser and help you take notes and manage them by deleting it",
    image:
      "https://i.imgur.com/3mJrzpo.png",
    tags: ["HTML", "CSS", "JAVASCRIPT", "Local Storage"],
    category: "web app",
    github: "https://github.com/usourabh/Notsy",
    member: [
      {
        name: "Sourabh",
        img: "https://i.imgur.com/SPD9Den.jpg",
        linkedin: "https://www.linkedin.com/in/sourabh-upadhyay99/",
        github: "https://github.com/usourabh/",
      },
    ],
  },
  {
    id: 2,
    title: "Movieopedia",
    date: "March 2022",
    description:
      "Designed and developed a movie search web app which consumes the api of moviedb.org and fetch the movie result based on the search term. It also shows the overview of a particular movie by hovering on it.",
    image:
      "https://i.imgur.com/hQnJYZY.png",
    tags: ["HTML", "CSS", "JAVASCRIPT", "API", "Moviedb.org"],
    category: "web app",
    github: "https://github.com/usourabh/Movieopedia",
    member: [
      {
        name: "Sourabh",
        img: "https://i.imgur.com/SPD9Den.jpg",
        linkedin: "https://www.linkedin.com/in/sourabh-upadhyay99/",
        github: "https://github.com/usourabh/",
      },
    ],
  },
  {
    id: 3,
    title: "Blockerium",
    date: "January 2022",
    description:
      "Blockerium is a productivity tool which helps you to stay focused on your work without any distraction. You just have to pass a website name into the python script and blockerium will take care of the rest. It will block the website for the desired time mentioned in the python script it uses host file of the windows OS to block you to particular website",
    image:
      "https://i.imgur.com/PQMnTdF.png",
    tags: ["HTML", "CSS", "JAVASCRIPT", "Python", "Windows host file"],
    category: "Python Script",
    github: "https://github.com/usourabh/Blockerium",
    member: [
      {
        name: "Sourabh",
        img: "https://i.imgur.com/SPD9Den.jpg",
        linkedin: "https://www.linkedin.com/in/sourabh-upadhyay99/",
        github: "https://github.com/usourabh/",
      },
    ],
  },
  {
    id: 4,
    title: "Word-Finder",
    date: "December 2021",
    description:
      "Word-Finder is a dictionary python script which has a JSON as a base file from which the script finds the meaning of the word. It is a command line dictionary script in which you enter the word and it gives you the meaning of that particular word",
    image:
      "https://i.imgur.com/I8lSRcA.png",
    tags: ["Python", "Json", "File Read Write in Python","Command-line Script"],
    category: "Python Script",
    github: "https://github.com/usourabh/Word-finder",
    member: [
      {
        name: "Sourabh",
        img: "https://i.imgur.com/SPD9Den.jpg",
        linkedin: "https://www.linkedin.com/in/sourabh-upadhyay99/",
        github: "https://github.com/usourabh/",
      },
    ],
  },
  // {
  //   id: 1,
  //   title: "Vexa",
  //   date: "Oct 2022 - Present",
  //   description:
  //     "Designed and developed the Vexa project, a project management app that helps users and teams stay organized and on track. Implemented key features such as task tracking, team collaboration, and time tracking to improve productivity and project outcomes and also invite team/project members. The plan is to add a community of users where they can find developers and other team members and chat system implementation.",
  //   image:
  //     "https://user-images.githubusercontent.com/64485885/234916413-96296f13-fe4b-4cc4-b215-e72bd7c27928.png",
  //   tags: [
  //     "React Js",
  //     "MongoDb",
  //     "Node Js",
  //     "Express Js",
  //     "Redux",
  //     "NodeMailer",
  //   ],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Project-Management-App",
  //   webapp: "https://vexa-app.netlify.app/",
  // },
  // {
  //   id: 2,
  //   title: "Brain Tumor Detection",
  //   date: "Jan 2023 - Mar 2023",
  //   description:
  //     "Preprocessed and augmented the dataset to improve model accuracy, trained the model, created API using model and Python, and used React web app for the project's front end. Achievements: Achieved an accuracy of 99.2% to accurately detect brain tumors from medical images.",
  //   image:
  //     "https://github.com/rishavchanda/Brain-Tumor-Detection/raw/main/Readme_resource/Image2.png",
  //   tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
  //   category: "machine learning",
  //   github: "https://github.com/rishavchanda/Brain-Tumor-Detection",
  //   webapp: "https://brain-tumor.netlify.app/",
  //   member: [
  //     {
  //       name: "Rishav Chanda",
  //       img: "https://avatars.githubusercontent.com/u/64485885?v=4",
  //       linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
  //       github: "https://github.com/rishavchanda/",
  //     },
  //     {
  //       name: "Upasana Chaudhuri",
  //       img: "https://avatars.githubusercontent.com/u/100614635?v=4",
  //       linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
  //       github: "https://github.com/upasana0710",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Buckoid",
  //   date: "Dec 2021 - Apr 2022",
  //   description:
  //     "App Is Currently In Playstore 100+ Downloads. This Project proposes an “Expense Tracking App”. Keep track of your personal expenses and compare them to your monthly income with the budget planner. It has Google Drive Cloud API for Backup of User Room Database. Made with Kotlin in MVVM Architecture & Live Data.",
  //   image:
  //     "https://camo.githubusercontent.com/fbf405040ae86b5d4a40f24e4ac034982cb8c0e2d850560ba64527997b258be8/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f6c6f67696e2d65613565322e61707073706f742e636f6d2f6f2f4255434b4f49442532302831292e706e673f616c743d6d6564696126746f6b656e3d32653735376235372d323964372d346263612d613562322d653164346538313432373435",
  //   tags: ["Kotlin", "MVVM", "Room Database", "Google Drive Cloud API"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Buckoid-Android-App",
  //   webapp: "https://play.google.com/store/apps/details?id=com.rishav.buckoid",
  // },
  // {
  //   id: 10,
  //   title: "Job Finding App",
  //   date: "Jun 2023 - Jul 2023",
  //   description:
  //     "A Job Finding App made with React Native, Axios. Users can search for any job coming from API and apply there.",
  //   image:
  //     "https://user-images.githubusercontent.com/64485885/255237090-cf798a2c-1b41-4bb7-b904-b5353a1f08e8.png",
  //   tags: ["React Native", "JavaScript", "Axios"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Job-finder-App",
  //   webapp: "https://github.com/rishavchanda/Job-finder-App",
  // },
  // {
  //   id: 4,
  //   title: "Whatsapp Clone",
  //   date: "Jul 2021",
  //   description:
  //     "A WhatsApp clone made with React JS, Firebase, and Material UI. It has Phone Authentication, Real-time Database. It has a chat room where users can chat with each other. It has a sidebar where users can see all the chat rooms and can create a new chat room. It has a login page where users can log in with their Google account.",
  //   image:
  //     "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-rishav.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
  //   tags: ["React Js", "Firebase", "Firestore", "Node JS"],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Whatsapp-Clone-React-Js",
  //   webapp: "https://whatsapp-clone-rishav.web.app",
  // },
  // {
  //   id: 5,
  //   title: "Todo Web App",
  //   date: "Jun 2021",
  //   description:
  //     " A Todo Web App made with React JS, Redux, and Material UI. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the tasks and can create a new task. It has a calendar where users can see all the tasks on a particular date. It has a search bar where users can search for a particular task.",
  //   image:
  //     "https://camo.githubusercontent.com/a328255ad96f861f57d25096d28018ab2656c689a1456b0d145764009bed2d1a/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f74253230283938292e706e673f616c743d6d6564696126746f6b656e3d33643335646366322d626666322d343730382d393031632d343232383866383332386633",
  //   tags: ["React Js", "Local Storage", "AWS Auth", "Node JS"],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Todo-Web-App",
  //   webapp: "https://rishav-react-todo.netlify.app/",
  // },
  // {
  //   id: 6,
  //   title: "Breaking Bad",
  //   date: "Jun 2021",
  //   description:
  //     "A simple react app that shows the characters of the famous TV series Breaking Bad. It uses the Breaking Bad API to fetch the data. It also has a search bar to search for a particular character.",
  //   image:
  //     "https://camo.githubusercontent.com/cd07010cbeb90cb1b43a5d6162784326aef02210ef7d41a0f9ae043b3e392378/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f7425323028313534292e706e673f616c743d6d6564696126746f6b656e3d65613439383630632d303435362d343333342d616435372d336239346663303333363263",
  //   tags: ["React Js", "API", "Axios", "Node JS"],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Breaking-Bad",
  //   webapp: "https://breaking-bad-webapp.netlify.app",
  // },
  // {
  //   id: 7,
  //   title: "Quiz App",
  //   date: "Dec 2020 - Jan 2021",
  //   description:
  //     "A android quiz app made with Java and Firebase. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the quiz categories and can create a new quiz. It has a leaderboard where users can see the top 10 scorers. It has a search bar where users can search for a particular quiz.",
  //   image:
  //     "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
  //   tags: ["Java", "Android Studio", "Firebase", "Google Auth"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Quiz-Earn",
  //   webapp: "https://github.com/rishavchanda/Quiz-Earn",
  // },
  // {
  //   id: 8,
  //   title: "Face Recognition",
  //   date: "Jan 2021",
  //   description:
  //     "A Face recognition python app made with OpenCV. It uses face_recognition library to detect faces. It uses the webcam to detect faces. It also has a search bar to search for a particular face.",
  //   image:
  //     "https://camo.githubusercontent.com/a8b3e1ebf26d4c95f75bc6671189a3590eb67eb8332d7c7452492990e731efb9/68747470733a2f2f77617265686f7573652d63616d6f2e696e67726573732e636d68312e707366686f737465642e6f72672f356137383335396561323762643261633232336437656662306639303831306437373930383436312f363837343734373037333361326632663633366336663735363432653637363937343638373536323735373336353732363336663665373436353665373432653633366636643266363137333733363537343733326633383339333633363339333232663332333433343333333033333339333832663333333636363330363533333636333032643331333336333632326433313331363533373264333833323335333832643334363433303633333936333635333136353334333133393265363736393636",
  //   tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
  //   category: "machine learning",
  //   github: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  //   webapp: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  // },
];

// export const TimeLineData = [
//   { year: 2017, text: "Started my journey" },
//   { year: 2018, text: "Worked as a freelance developer" },
//   { year: 2019, text: "Founded JavaScript Mastery" },
//   { year: 2020, text: "Shared my projects with the world" },
//   { year: 2021, text: "Started my own platform" },
// ];
